<template>
  <div class="container">
    <el-tabs
      v-model="activeName"
      class="box1"
      type="card"
      @click="changePane"
    >
      <!-- 第一步 -->
      <el-tab-pane
        label="第一步:基本信息"
        name="one"
      >
        <div class="formbox">
          <el-button
            class="btn_e6a23c"
            style="margin-bottom: 20px"
            @click="addOrUpdateChannel()"
          >
            保存并下一步
          </el-button>
          <el-form
            :inline="true"
            label-position="right"
            label-width="150px"
            :model="formInline"
            class="demo-form-inline formDisplay"
          >
            <div style="width: 100%; font-size: 14px; margin: 10px 30px">
              基本信息
            </div>

            <el-form-item
              label="渠道简称:"
              class="queryItem1"
            >
              <el-input
                v-model="formInline.channelName"
                placeholder="请输入"
                class="input"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="大货起始重量(kg):"
              class="queryItem1"
            >
              <el-input
                v-model="formInline.bigGoodsStartWeight"
                placeholder="请输入"
                class="input"
              ></el-input>
            </el-form-item>

            <el-form-item
              label="运输方式:"
              class="queryItem1"
            >
              <el-select
                v-model="formInline.channelTransportId"
                placeholder="请选择"
                class="input"
              >
                <el-option
                  v-for="item in TransportMethodList"
                  :key="item.transportId"
                  :label="item.transportName"
                  :value="item.transportId"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <!-- <el-form-item label="渠道:" class="queryItem1">
                          <el-select
                            v-model="formInline.channelId"
                            placeholder="请选择"
                            class="input"
                          >
                            <el-option
                              v-for="item in ChannelList"
                              :key="item.transportId"
                              :label="item.transportName"
                              :value="item.transportId"
                            >
                            </el-option>
                          </el-select>
                        </el-form-item> -->
            <el-form-item
              label="排序:"
              class="queryItem1"
            >
              <el-input
                v-model="formInline.sort"
                placeholder="请输入"
                class="input"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="备注:"
              class="queryItem_text"
            >
              <el-input
                type="textarea"
                rows="3"
                v-model="formInline.comment"
                placeholder="请输入"
                class="input"
              ></el-input>
            </el-form-item>
            <div style="width: 100%; font-size: 14px; margin: 10px 30px">
              计算规则
            </div>
            <el-form-item
              label="材积除:"
              class="queryItem1"
            >
              <el-select
                v-model="formInline.channelDivisorId"
                placeholder="请选择"
                class="input"
              >
                <el-option
                  v-for="item in DivisorList"
                  :key="item.divisorId"
                  :label="item.divisorName"
                  :value="item.divisorId"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="计费重量进位规则:"
              class="queryItem1"
            >
              <el-select
                v-model="formInline.formulaBillingWeightCarryId"
                placeholder="请选择"
                class="input"
              >
                <el-option
                  v-for="item in FormulaBillingWeithtCarryAllList"
                  :key="item.formulaBillingWeightCarryId"
                  :label="item.name"
                  :value="item.formulaBillingWeightCarryId"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="计费重量计算公式:"
              class="queryItem1"
            >
              <el-select
                v-model="formInline.formulaBillingWeightId"
                placeholder="请选择"
                class="input"
              >
                <el-option
                  v-for="item in FormulaBillingWeithtAllList"
                  :key="item.formulaBillingWeightId"
                  :label="item.name"
                  :value="item.formulaBillingWeightId"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="发出仓库:"
              class="queryItem1"
            >
              <el-select
                v-model="startStorages"
                placeholder="请选择"
                class="input multipleclass"
                multiple
              >
                <el-option
                  v-for="item in cangkuList"
                  :key="item.id"
                  :label="item.storageName"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="到达仓库:"
              class="queryItem1"
            >
              <el-select
                v-model="endStorages"
                placeholder="请选择"
                class="input multipleclass"
                multiple
              >
                <el-option
                  v-for="item in cangkuList"
                  :key="item.id"
                  :label="item.storageName"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <!-- <el-form-item label="备注:" class="beizhuBox" style="width: 100%">
                          <el-input
                            type="textarea"
                            v-model="formInline.aaaaaa"
                            placeholder="请输入"
                            class="inputText"
                          ></el-input>
                        </el-form-item> -->
          </el-form>
        </div>
      </el-tab-pane>
      <!-- 第二步 -->
      <el-tab-pane
        :disabled="NowchannelId == ''"
        label="第二步:渠道分区"
        name="three"
      >
        <div class="formbox formBox2">
          <el-button
            class="btn_s"
            style="margin-right: 20px"
            @click="addNewFenQu"
          >
            新增分区
          </el-button>
          <el-button
            class="btn_s"
            style="margin-right: 20px"
            @click="daoRuFenQu"
          >
            导入分区
          </el-button>
          <el-button
            class="btn_e6a23c"
            style="margin-bottom: 20px"
            @click="nextStep2()"
          >
            下一步
          </el-button>

          <!-- 分区报价表格 -->
          <div class="form_fq">
            <el-table
              :data="tableData2"
              height="70vh"
              border
              stripe
              style="width: 100%"
            >
              <el-table-column
                fixed="left"
                label="序号"
                type="index"
                width="60"
              ></el-table-column>
              <!-- <el-table-column fixed="left" type="selection" width="60"> -->

              <!-- <el-table-column
                prop="channelOfferName"
                label="渠道报价名称"
                min-width="100"
              ></el-table-column> -->
              <el-table-column
                prop="number"
                label="分区编码"
                min-width="80"
              >
              </el-table-column>
              <el-table-column
                min-width="120"
                prop="partitionName"
                label="分区名称"
              ></el-table-column>
              <el-table-column
                min-width="180"
                prop="areaNames"
                label="国家 提示:[以,.符号分割]"
              ></el-table-column>
              <el-table-column
                min-width="120"
                prop="noImportAreaNames"
                label="未选中国家"
              >
              </el-table-column>
              <el-table-column
                prop="deliveryComment"
                label="递送时间"
                min-width="100"
              >
              </el-table-column>
              <el-table-column
                label="操作"
                min-width="170"
              >
                <template slot-scope="scope">
                  <el-button
                    class="btn_e6a23c formBtn"
                    style="margin-right: 10px"
                    @click="editBtn(scope.$index)"
                  >
                    编辑目的地
                  </el-button>
                  <el-button
                    class="formBtn"
                    @click="delBtn(scope.row)"
                  >删除
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
            <!-- 分页组件 -->
            <paging
              ref="pags"
              :pageNum="pageNum"
              :total="total"
              :sizeList="sizeList"
              :size="size"
              @handleSizeChange="handleSizeChange"
              @handleCurrentChange="handleCurrentChange"
            ></paging>
          </div>
        </div>
      </el-tab-pane>
      <!-- 第三步 -->
      <el-tab-pane
        :disabled="NowchannelId == ''"
        label="第三步:货物类别"
        name="four"
      >
        <div class="formbox">
          <el-button
            class="btn_e6a23c"
            style="margin-bottom: 20px"
            @click="nextStep3()"
          >
            保 存
          </el-button>

          <div class="categoryTitle">渠道可走货物</div>
          <div>
            <el-checkbox
              :indeterminate="isIndeterminate"
              v-model="checkAll"
              @change="handleCheckAllChange"
            >全选</el-checkbox>
            <div style="margin: 15px 0"></div>
            <el-checkbox-group
              v-model="checkedCities"
              @change="handleCheckedCitiesChange"
            >
              <el-checkbox
                v-for="items in ItemCategoryList"
                :label="items.itemCategoryId"
                :key="items.itemCategoryId"
              >{{ items.showName }}
              </el-checkbox>
            </el-checkbox-group>
          </div>
        </div>
      </el-tab-pane>
      <!-- 第五步 -->
      <!-- <el-tab-pane label="渠道修改日志" name="five"></el-tab-pane> -->
    </el-tabs>

    <!-- 新增报价分区  弹窗 -->
    <el-dialog
      :title="diaTitle"
      append-to-body
      :visible.sync="showDia_fq"
      @close="closeDias"
      width="60%"
      center
    >
      <div class="diamain">
        <el-form
          :inline="true"
          label-position="right"
          label-width="120px"
          :model="infosForm"
          class="formDia"
          @submit.native.prevent
        >
          <el-form-item
            label="分区编号:"
            class="queryItem2"
          >
            <el-input
              v-model="infosForm.number"
              placeholder=""
              class="input"
              style="width: 100%"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="分区名称:"
            class="queryItem2"
          >
            <el-input
              v-model="infosForm.partitionName"
              placeholder=""
              class="input"
              style="width: 100%"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="递送时间:"
            class="queryItem2"
          >
            <el-input
              v-model="infosForm.deliveryComment"
              placeholder="请输入"
              class="input"
              style="width: 100%"
            ></el-input>
          </el-form-item>
          <el-form-item
            v-if="diaTitle === '编辑分区'"
            label="未选中国家:"
            class="queryItem2"
          >
            <div class="tagClass">
              <div class="tagList">
                <el-input v-model="noImportAreaNamesStr"> </el-input>
              </div>
              <el-button
                class="btn_s"
                :disabled="isAble"
                style="width: 68px; margin-right: 12px"
                @click.stop="clearArr_area()"
              >清 空
              </el-button>
            </div>
          </el-form-item>
          <el-form-item
            label="已选国家地区:"
            class="queryItem2"
          >
            <div class="tagClass">
              <div class="tagList">
                <el-tag
                  v-for="tag in changeCountry"
                  :key="tag.chineseName"
                  closable
                  @close="closeTag(tag.areaCountryId)"
                  type="warning"
                  style="
                    margin-left: 10px;
                    background-color: #fff;
                    color: #e6a23c;
                  "
                >
                  {{ tag.chineseName }}
                </el-tag>
              </div>
              <el-button
                class="btn_s"
                :disabled="isAble"
                style="width: 68px; margin-right: 12px"
                @click.stop="clearArr()"
              >清 空
              </el-button>
            </div>
          </el-form-item>
          <el-form-item class="queryItem3">
            <el-input
              placeholder="查询国家"
              v-model="areaContryNameCn"
              class="inputQuery"
              :onsubmit="false"
              @keyup.enter.native="queryBtn()"
            >
              <template
                slot="append"
                class="btnRight"
              >
                <el-button
                  class="btn_s"
                  @click="queryBtn()"
                > 查询</el-button>
              </template>
            </el-input>
          </el-form-item>
        </el-form>
        <el-table
          class="areaCountTable"
          ref="areaTable"
          :data="areaCountryArr"
          border
          stripe
          @select="selectssss"
          @select-all="selectssssAll"
          @selection-change="selectionChange"
          style="width: 100%; margin-top: 20px"
        >
          <el-table-column
            label="序号"
            type="index"
            width="60"
          >
          </el-table-column>
          <el-table-column
            type="selection"
            width="60"
          ></el-table-column>
          <el-table-column
            prop="chineseName"
            label="国家名"
          ></el-table-column>
          <el-table-column
            prop="numberCode"
            label="编码"
          ></el-table-column>
        </el-table>
      </div>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          type="primary"
          @click="showDia_save"
        >确 定</el-button>
      </span>
    </el-dialog>
    <!-- 导入报价分区  弹窗 -->
    <el-dialog
      title="导入分区"
      append-to-body
      :visible.sync="showDaoRuFenQu"
      @close="closeshowDaoRuFenQu"
      width="60%"
      center
    >
      <div class="">
        <div>
          提示:
          导入分区将删除原有分区信息,从Excel中直接拷贝,两个数字自己采用Tab键进行分割,两行之间采用分行进行分割
        </div>
        <div class="titHead">
          <div class="titItem">国家地区</div>
          <div class="titItem">分区名称</div>
        </div>
        <el-input
          v-model="daoRuVal"
          type="textarea"
          placeholder="请粘贴内容"
          rows="20"
        ></el-input>
      </div>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          type="primary"
          @click="showDiaDaoRu_save"
        >确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { Api } from '@/assets/js/api'
import tools from '@/assets/js/tool'
import paging from '@/components/pagings.vue'

export default {
  inject: ['reload'],
  name: 'NewChannel',
  components: { paging },
  data() {
    return {
      checkAll: false,
      isIndeterminate: false,
      showDia_fq: false,
      showDaoRuFenQu: false,
      daoRuVal: '',
      diaTitle_bj: '新增报价',
      diaTitle: '新增报价分区',
      formInline: {
        bigGoodsStartWeight: '', //大货起始重量kg
        channelDivisorId: '', //材积除id
        channelId: '', //渠道id
        channelName: '', //渠道名称
        channelTransportId: '', //运输方式id
        formulaBillingWeightCarryId: '', //计费重量进位规则id
        formulaBillingWeightId: '', //计费重量公式id
        sort: '1', //排序
        comment: '', //
        startStorages: [],
        endStorages: [],
        // aaaaaa: "123",
      },
      changeCountry: [], //已选的国家
      activeName: 'one',
      TransportMethodList: [], //运输方式列表
      ChannelList: [], //渠道列表
      DivisorList: [], //材积除列表
      FormulaBillingWeithtAllList: [], //计费重量计算规则
      FormulaBillingWeithtCarryAllList: [], //计费重量进位规则
      tableData1: [], //第二步,报价表
      tableData2: [], //第三步,分区
      areaCountryArr: [],
      infosForm: {
        areaCountryIds: '',
        partitionName: '',
        deliveryComment: '',
        number: '',
        channelOfferId: '', //报价id
      },
      noImportAreaNamesStr: '',
      NowchannelId: '', //当前渠道id
      NowchannelOfferId: '', //当前渠道报价id
      pageNum: 1, //页码
      size: 20, //一页几条
      total: 0, //总条数
      pageNum2: 1, //页码
      size2: 20, //一页几条
      total2: 0, //总条数
      sizeList: [10, 20, 50, 100, 200],
      areaContryNameCn: '', //国家查询条件

      formData2: {
        //报价
        offerName: '', //报价名
        times: '',
      },
      editData: [], //修改时传过来的
      pageType: '', // edit == 修改
      diaStyle: '',
      isAble: false,
      ItemCategoryList: [], //渠道货物列表
      piLiangFenQe: [], //批量导入分区

      checkedCities: [], //已选货物品类
      channelOfferPartitionId: '', //  渠道分区id
      notSelectareaCountryNames: [], //  未选中国家数组
      cangkuList: [], //仓库数组
      startStorages: [],
      endStorages: [],
    }
  },
  //keep-alive才有,激活页面方法
  activated() {
    if (this.$route.query.data) {
      this.editData = JSON.parse(this.$route.query.data) || []
      this.pageType = this.$route.query.pageType || ''
      if (this.editData.length > 0) {
        this.formInline = this.editData[0]
        this.NowchannelId = this.formInline.channelId
      }
      this.activeName = 'one'
    }
    if (this.$route.query.pageType == 'add') {
      this.formInline = {
        bigGoodsStartWeight: '', //大货起始重量kg
        channelDivisorId: '', //材积除id
        channelId: '', //渠道id
        channelName: '', //渠道名称
        channelTransportId: '', //运输方式id
        formulaBillingWeightCarryId: '', //计费重量进位规则id
        formulaBillingWeightId: '', //计费重量公式id
        comment: '', //
        sort: '1', //排序
        startStorages: [],
        endStorages: [],
      }
      this.activeName = 'one'
    }
  },
  created() {
    console.log('created')
    // this.getAllAreas();
    if (this.$route.query.data) {
      this.editData = JSON.parse(this.$route.query.data) || []
      this.pageType = this.$route.query.pageType || ''
      if (this.editData.length > 0) {
        this.formInline = this.editData[0]
        this.NowchannelId = this.formInline.channelId
        console.log(123)
        this.getChannelStorageInfoV4(this.NowchannelId)
      }
    }
  },
  watch: {
    activeName: function (newVal, old) {
      if (newVal == 'two') {
        this.getChannelOfferList()
      }
      if (newVal == 'three') {
        this.getChannelOfferPartitionList()
      }
      if (newVal == 'four') {
        this.getItemCategoryListNoPage()
        if (this.pageType == 'edit') {
          this.myGetChannelItemCategory()
        }
      }
    },
    noImportAreaNamesStr: function (newVal, old) {
      if (newVal.length > 0) {
        let arr = newVal.split(',')
        this.notSelectareaCountryNames = arr
      }
    },
    changeCountry: function (newVal, old) {
      if (newVal.length > 0) {
        this.isAble = false
      } else {
        this.isAble = true
      }
    },
    // 导入的报价
    daoRuVal: function (newVal, old) {
      // console.log(newVal)
      // console.log('导入的数据')
      var String = []
      String.push(newVal)

      let datsArr = String[0].split('\n') || []
      if (datsArr.length <= 0) {
        return
      }

      let flag = /\s/ //所有空白字符
      let newArr = [] //切割后的数组
      for (var i = 0; i < datsArr.length; i++) {
        if (datsArr[i]) {
          let strArr = datsArr[i].split(flag)
          let arr1 = []
          for (var z = 0; z < strArr.length; z++) {
            if (strArr[z]) {
              arr1.push(strArr[z])
            }
          }
          newArr.push(arr1)
        }
      }
      if (newArr.length == 0) {
        return
      }
      let arr1 = []
      for (var a = 0; a < newArr.length; a++) {
        if (newArr[a][0]) {
          let guojiaList = newArr[a][0].split(',') || []
          for (var b = 0; b < guojiaList.length; b++) {
            if (guojiaList[b]) {
              let obj = {
                areaCountryName: guojiaList[b],
                partitionName: newArr[a][1],
                deliveryComment: '', //备注
              }
              arr1.push(obj)
            }
          }
        }
      }
      console.log(arr1)
      console.log('arr1')
      this.piLiangFenQe = arr1
    },
  },
  mounted() {
    this.getTransportMethodList()
    this.getChannelList()
    this.getDivisorList()
    this.getFormulaBillingWeithtCarryAllList()
    this.getFormulaBillingWeithtAllList()
    this.myGetStorageList()
  },
  methods: {
    getChannelStorageInfoV4(channelId) {
      Api.getChannelStorageInfoV4({channelId}).then((res) => {
        const result = res.data.result || []
        this.startStorages = result.startStorages && result.startStorages.map(n=>{ return String(n.storageId)})
        this.endStorages = result.endStorages && result.endStorages.map(n=>{ return String(n.storageId)})
      })
    },
    myGetStorageList() {
      //  获取仓库下拉列表
      Api.getStorageList().then((res) => {
        this.cangkuList = res.data.result || []
      })
    },
    // 选择货物类别
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length
      this.checkAll = checkedCount === this.ItemCategoryList.length
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.ItemCategoryList.length
    },
    //全选
    handleCheckAllChange(val) {
      let arrs = this.ItemCategoryList || []
      let arrIds = []
      arrs.forEach((item) => {
        arrIds.push(item.itemCategoryId)
      })
      this.checkedCities = val ? arrIds : []
      this.isIndeterminate = false
    },

    // 获取渠道货物类别
    getItemCategoryListNoPage() {
      Api.getItemCategoryListNoPage().then((res) => {
        if (res.data.status === 'success') {
          this.ItemCategoryList = res.data.result || []
        }
      })
    },

    //编辑报价分区地址
    editBtn(e) {
      this.diaTitle = '编辑分区'
      this.diaStyle = 'edit'
      this.showDia_fq = true
      let datas = this.tableData2[e]

      this.changeCountry = datas.areaCountryList
      this.noImportAreaNamesStr = datas.noImportAreaNames
      this.infosForm.partitionName = datas.partitionName
      this.infosForm.number = datas.number
      this.infosForm.deliveryComment = datas.deliveryComment || ''
      this.channelOfferPartitionId = datas.channelOfferPartitionId
      this.NowchannelOfferId = datas.channelOfferId
    },
    //删除报价分区地址
    delBtn(row) {
      let that = this
      this.$confirm('是否删除该分区?', '提示:', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      })
        .then(() => {
          let param = {
            offerPartitionId: row.channelOfferPartitionId,
          }
          let sign = tools.getSign(param)
          param.sign = sign
          Api.delChannelOfferPartition(param).then((res) => {
            if (res.data.status == 'success') {
              that.$message.success(res.data.message || '删除成功')
              setTimeout(() => {
                that.getChannelOfferPartitionList()
              }, 800)
            }
          })
        })
        .catch(() => {})
    },

    //保存报价
    nextStep1() {
      let times = this.formData2.times
      console.log(times)
      var param = {
        channelId: this.NowchannelId,
        effectiveStartTime: this.formData2.times[0] || '',
        effectiveEndTime: this.formData2.times[1] || '',
        offerName: this.formData2.offerName,
        // offerId:'',//报价id(修改时必传)
      }
      let sign = tools.getSign(param)
      param.sign = sign
      Api.addOrUpdateChannelOffer(param).then((res) => {
        if (res.data.status === 'success') {
          this.$message.success(res.data.message || '操作成功')
          this.NowchannelOfferId = res.data.result.channelOfferId || ''
          this.activeName = 'three'
        }
      })
    },
    nextStep2() {
      this.activeName = 'four'
    },
    nextStep3() {
      let param = {}
      param.itemCategoryIds = []
      param.channelId = this.NowchannelId
      param.itemCategoryIds = this.checkedCities
      param.sign = tools.getSign(param)
      Api.updateChannelItemCategory(param).then((res) => {
        if (res.data.status === 'success') {
          this.$message.success(res.data.message || '操作成功')
          tools.closePage()
          this.reload()
          // setTimeout(() => {
          //   this.$route.push("/PayableManagement/channelManagement");
          // }, 1000);
        }
      })
    },
    //选择第几步
    changePane(e) {
      console.log(e)
    },
    // 关闭标签
    closeTag(e) {
      let id = e
      this.changeCountry.forEach((item, index) => {
        if (item.areaCountryId == id) {
          this.changeCountry.splice(index, 1)
          return
        }
      })
    },
    // 清空分区
    clearArr() {
      this.$confirm('确定清空分区吗?', '提示:', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      })
        .then(() => {
          this.changeCountry = []
          this.getAllAreas()
        })
        .catch(() => {})
    },
    // 清空分区
    clearArr_area() {
      this.$confirm('确定清空未选中的国家地区吗?', '提示:', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      })
        .then(() => {
          this.noImportAreaNamesStr = ''
          this.notSelectareaCountryNames = []
        })
        .catch(() => {})
    },

    // 保存报价分区
    addNewFenQu() {
      this.showDia_fq = true
    },
    // 保存报价分区
    daoRuFenQu() {
      this.showDaoRuFenQu = true
    },
    // 保存报价
    addNew_baoJia() {
      // this.showDia_fq = true;
    },
    //批量保存分区
    showDiaDaoRu_save() {
      let param = {
        channelId: this.NowchannelId,
        partitionList: this.piLiangFenQe || [], //分区信息
      }
      console.log(param)
      Api.batchAddOfferPartition(param).then((res) => {
        if (res.data.status == 'success') {
          this.$message.success(res.data.message || '导入成功')
          this.showDaoRuFenQu = false
          this.getChannelOfferPartitionList()
        }
      })
    },
    //修改编辑分区
    showDia_save() {
      let ids = []
      for (var i = 0; i < this.changeCountry.length; i++) {
        ids.push(this.changeCountry[i].areaCountryId)
      }
      let signParam = {
        partitionName: this.infosForm.partitionName, //分区名称
        deliveryComment: this.infosForm.deliveryComment, //递送时间
        channelId: this.NowchannelId, //
        areaCountryIds: ids, //国家地区id数组
        number: this.infosForm.number,
        channelOfferPartitionId: this.channelOfferPartitionId, //渠道分区id, 修改必传
        notSelectareaCountryNames: this.notSelectareaCountryNames, //未选中国家数组
      }

      let sign = tools.getSign(signParam)
      signParam.sign = sign
      Api.addOrUpdateChannelOfferPartition(signParam).then((res) => {
        if (res.data.status === 'success') {
          this.showDia_fq = false
          this.$message.success(res.data.message || '操作成功')
          this.getChannelOfferPartitionList()
          // this.reload_s();
        }
      })
    },
    // 获取报价列表
    getChannelOfferList() {
      var param = {
        channelId: this.NowchannelId, //渠道id
        offerName: this.formData2.offerName, //报价名
        pageStart: this.pageNum2,
        pageTotal: this.size2,
      }
      Api.getChannelOfferList(param).then((res) => {
        if (res.data.status == 'success') {
          let data = res.data.result.data || []
          this.total2 = res.data.result.pageTotal || 0
          this.tableData1 = data
        }
      })
    },
    // 获取报价分区列表
    getChannelOfferPartitionList() {
      var param = {
        channelId: this.NowchannelId, //渠道id
        pageStart: this.pageNum,
        pageTotal: this.size,
        partitionName: '', //分区名
      }
      Api.getChannelOfferPartitionList(param).then((res) => {
        if (res.data.status == 'success') {
          let tableData2 = res.data.result.data || []
          this.total = res.data.result.pageCount || 0
          for (var i = 0; i < tableData2.length; i++) {
            var srt = ''
            var srt3 = ''
            let arr2 = tableData2[i].areaCountryList || []
            let arr3 = tableData2[i].areaCountryNotImportList || []
            for (var j = 0; j < arr2.length; j++) {
              srt += arr2[j].chineseName + ','
            }
            let noporArr = []
            for (var a = 0; a < arr3.length; a++) {
              noporArr.push(arr3[a].areaCountryName)
              srt3 += arr3[a].areaCountryName + ','
            }
            this.notSelectareaCountryNames = noporArr
            if (srt) {
              srt = srt.substring(0, srt.length - 1) //删除最后一个逗号 ,
            }
            if (srt3) {
              srt3 = srt3.substring(0, srt3.length - 1) //删除最后一个逗号 ,
            }
            tableData2[i].areaNames = srt
            tableData2[i].noImportAreaNames = srt3
          }
          this.tableData2 = tableData2
        }
      })
    },
    //分页
    handleSizeChange(val) {
      this.size = val
      this.getChannelOfferPartitionList()
    },
    handleCurrentChange(val) {
      this.pageNum = val
      this.getChannelOfferPartitionList()
    },
    //分页
    handleSizeChange2(val) {
      this.size2 = val
      this.getChannelOfferList()
    },
    handleCurrentChange2(val) {
      this.pageNum2 = val
      this.getChannelOfferList()
    },
    //获取国家地区下拉框
    getAllAreas() {
      let param = {
        areaContryNameCn: this.areaContryNameCn, //中文名
        // areaContryNameEn: this.addFenQuData.areaContryNameEn,//英文名
        pageStart: 1,
        pageTotal: 200,
      }
      Api.getAreaContryList(param).then((res) => {
        if (res.data.status == 'success') {
          let areaCountryArr = res.data.result.data || []
          this.areaCountryArr = areaCountryArr

          let changeCountry = this.changeCountry || []
          this.$nextTick(function () {
            for (var i = 0; i < changeCountry.length; i++) {
              for (var j = 0; j < areaCountryArr.length; j++) {
                if (
                  changeCountry[i].areaCountryId ==
                  areaCountryArr[j].areaCountryId
                ) {
                  this.$refs.areaTable.toggleRowSelection(
                    areaCountryArr[j],
                    true
                  )
                }
              }
            }
          })
        }
      })
    },
    closeDias() {
      this.changeCountry = []
      this.areaCountryArr = []
      this.areaContryNameCn = ''
      this.infosForm = {
        areaCountryIds: '',
        deliveryComment: '',
        partitionName: '',
        number: '',
        channelOfferId: '', //报价id
      }
    },
    closeshowDaoRuFenQu() {
      this.daoRuVal = ''
    },
    closeDia_bj() {},
    //查询国家
    queryBtn() {
      this.getAllAreas()
    },

    // 勾选国家
    selectssss(selection, row) {
      console.log(123)
      let arr = this.changeCountry || []
      let eArr = selection
      let selected = selection.length > 0 && selection.indexOf(row) !== -1
      if (selected) {
        // console.log("选中");
        if (arr.length == 0) {
          this.changeCountry = eArr
          return
        }
        for (var a = 0; a < eArr.length; a++) {
          for (var b = 0; b < arr.length; b++) {
            if (eArr[a].areaCountryId == arr[b].areaCountryId) {
              eArr.splice(a, 1)
              if (a >= 1) {
                a--
              }
            }
          }
        }
        arr = arr.concat(eArr)
        this.changeCountry = arr
      } else {
        // console.log("取消");
        for (var c = 0; c < arr.length; c++) {
          if (arr[c].areaCountryId == row.areaCountryId) {
            arr.splice(c, 1)
            this.changeCountry = arr
            return
          }
        }
      }
    },
    //选择国家
    selectssssAll(e) {
      let arr = this.changeCountry || []
      let eArr = e
      console.log(eArr)
      let selected = eArr.length > 0
      if (selected) {
        // console.log("选中");
        if (arr.length == 0) {
          this.changeCountry = eArr
          return
        }
        for (var a = 0; a < eArr.length; a++) {
          for (var b = 0; b < arr.length; b++) {
            if (eArr[a].areaCountryId == arr[b].areaCountryId) {
              eArr.splice(a, 1)
              if (a >= 1) {
                a--
              }
            }
          }
        }
        arr = arr.concat(eArr)
        this.changeCountry = arr
      } else {
        // console.log("取消");
        if (arr.length == this.areaCountryArr.length) {
          this.changeCountry = []
        } else {
          let areaList = this.areaCountryArr || []
          for (var c = 0; c < areaList.length; c++) {
            for (var d = 0; d < arr.length; d++) {
              if (arr[d].areaCountryId == areaList[c].areaCountryId) {
                arr.splice(d, 1)
                d--
              }
            }
          }
        }
      }
    },
    selectionChange(e) {},

    //获取渠道列表
    getChannelList() {
      Api.getChannelList().then((res) => {
        let ChannelList = res.data.result.data || []
        this.ChannelList = ChannelList
      })
    },
    //获取运输方式列表
    getTransportMethodList() {
      Api.getTransportMethodList().then((res) => {
        let TransportMethodList = res.data.result.data || []
        this.TransportMethodList = TransportMethodList
      })
    },
    //获取材积除列表
    getDivisorList() {
      Api.getDivisorList().then((res) => {
        let DivisorList = res.data.result.data || []
        this.DivisorList = DivisorList
      })
    },
    //计费重量进位规则
    getFormulaBillingWeithtCarryAllList() {
      Api.getFormulaBillingWeithtCarryAllList().then((res) => {
        let FormulaBillingWeithtCarryAllList = res.data.result || []
        this.FormulaBillingWeithtCarryAllList = FormulaBillingWeithtCarryAllList
      })
    },
    //计费重量计算规则
    getFormulaBillingWeithtAllList() {
      Api.getFormulaBillingWeithtAllList().then((res) => {
        let FormulaBillingWeithtAllList = res.data.result || []
        this.FormulaBillingWeithtAllList = FormulaBillingWeithtAllList
      })
    },
    //保存第一步
    addOrUpdateChannel() {
      // let param = this.formInline;
      let startStorages = this.cangkuList
        .filter((n) => {
          if (this.startStorages.includes(n.id)) {
            return n
          }
        })
        .map((n, i) => {
          return {
            sort: i,
            storageId: n.id,
            storageName: n.storageName,
          }
        })
      let endStorages = this.cangkuList
        .filter((n) => {
          if (this.endStorages.includes(n.id)) {
            return n
          }
        })
        .map((n, i) => {
          return {
            sort: i,
            storageId: n.id,
            storageName: n.storageName,
          }
        })

      let param = {
        bigGoodsStartWeight: this.formInline.bigGoodsStartWeight,
        channelDivisorId: this.formInline.channelDivisorId, //材积除id
        channelId: this.formInline.channelId, //渠道id
        channelName: this.formInline.channelName, //渠道名称
        channelTransportId: this.formInline.channelTransportId, //运输方式id
        comment: this.formInline.comment, //备注
        formulaBillingWeightCarryId:
          this.formInline.formulaBillingWeightCarryId, //计费重量进位规则id
        formulaBillingWeightId: this.formInline.formulaBillingWeightId, //计费重量公式id
        sort: this.formInline.sort || '1', //排序
        startStorages,
        endStorages,
      }
      // if (this.pageType == "edit") {
      //   param.channelId = this.editData.channelId;
      // }
      let signs = tools.getSign(param)
      param.sign = signs
      Api.addOrUpdateChannelV4(param).then((res) => {
        if (res.data.status == 'success') {
          this.$message.success(res.data.message || '操作成功')
          if (res.data.result.channelId) {
            this.NowchannelId = res.data.result.channelId
          }
          setTimeout(() => {
            this.activeName = 'three'
          }, 1000)
        }
      })
    },

    myGetChannelItemCategory() {
      //  获取当前渠道可走货物
      Api.getChannelItemCategory({
        channelId: this.NowchannelId,
      }).then((res) => {
        if (res.data.status === 'success') {
          let arrs = res.data.result || []
          let arrIds = []
          arrs.forEach((item) => {
            arrIds.push(item.itemCategoryId)
          })
          this.checkedCities = arrIds
          console.log(arrIds)
          console.log('arrIds')
          if (arrIds.length == 0) {
            this.isIndeterminate = false
          }
        }
      })
    },
  },
}
</script>

<style scoped lang="scss">
.titHead {
  display: flex;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 6px;
  font-size: 16px;

  .titItem {
    margin-right: 50px;
    margin-left: 20px;
    font-weight: bold;
  }
}

.diamain {
  .queryBox {
    display: flex;
    align-items: center;

    .query_left {
      border: 1px solid #d7d7d7;
      line-height: 28px;
    }
  }
  ::v-deep {
    .el-form-item {
      margin-bottom: 10px;
    }
  }

  .formDia {
    .queryItem3 {
      width: 100%;
      min-height: 40px;
      display: flex;
      align-items: center;
      border: 1px solid #d7d7d7;
      margin-bottom: 0;
      ::v-deep {
        .el-form-item__label,
        .el-form-item__content,
        .el-input__inner {
          line-height: 28px !important;
          border: none;
          width: 100%;
        }
      }
    }
    .queryItem2 {
      width: 100%;
      // margin-right: 2%;
      min-height: 40px;
      display: flex;
      align-items: center;
      border: 1px solid #d7d7d7;
      ::v-deep {
        .el-form-item__label,
        .el-form-item__content,
        .el-input__inner {
          line-height: 28px !important;
          border: none;
          width: 100%;
        }
      }
      .tagClass {
        width: 100%;
        display: flex;
        // flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        background-color: #f5f7fa;
        // cursor:not-allowed;
        min-height: 40px;
        .tagList {
          flex: 1;
          display: flex;
          flex-wrap: wrap;
        }
      }

      .inputframe {
        height: 30px !important;
        line-height: 30px;
        ::v-deep {
          .el-input__inner {
            height: 28px !important;
          }
        }
      }
      .input {
        border: none;
        width: 100%;
      }

      .areaCount {
        cursor: pointer;
      }
    }
    .inputQuery {
      width: 100%;
      // margin-bottom: 10px;
      ::v-deep {
        .el-input-group__append {
          padding: 0 10px;
        }
        .el-input-group__append {
          width: 70px;
        }
      }
      .btn_s {
        background-color: #fff;
        color: #333;
        border: 1px solid #606266;
        padding: 8px 10px;
        border-radius: 4px;
        margin: 0 auto;
        cursor: pointer;
        width: 68px;
      }
      .btn_s:hover {
        color: #606266 !important;
        border: 1px solid #ef7c1b;
      }
    }
    .areaCountTable {
      margin-top: 20px;
    }
  }
}

.btn_e6a23c {
  background-color: #e6a23c;
  color: #fff;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
}

.btn_s {
  background-color: #fff;
  color: #333;
  border: 1px solid #606266;
  padding: 8px 10px;
  border-radius: 4px;
  margin-right: 20px;
  cursor: pointer;
}

.btn_s:hover {
  color: #606266 !important;
  border: 1px solid #ef7c1b;
}

.formBtn {
  cursor: pointer;
}

.categoryTitle {
  font-size: 14px;
  font-weight: bold;
}

.formbox {
  width: 80%;
  min-width: 800px;
  margin: 20px auto;
  //   padding: 10px 30px;
  box-sizing: border-box;
  //   display: flex;

  .formDisplay {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    ::v-deep .el-form-item {
      margin-bottom: 0;
    }

    .beizhuBox {
      width: 100%;
      margin-top: 30px;
      ::v-deep {
        .el-form-item__content {
          width: calc(100% - 150px);
        }
      }
      // .inputText {
      //     width: calc(100% - 150px);
      // }
    }

    .queryItem1 {
      min-width: 360px;
      width: 40%;
      margin: 3px 1%;
      border: 1px solid #cccccc;
      border-radius: 5px;
      display: flex;
      align-items: center;
      //   justify-content: space-between;
      .queryLabel {
        width: 70px;
        min-width: 70px;
        color: #606266;
        text-align: right;
        margin-right: 10px;
      }
      ::v-deep {
        .el-form-item__content {
          width: calc(100% - 96px);
          line-height: 30px;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
        .el-input__inner {
          line-height: 28px;
          height: 28px;
          border: none;
        }
        .el-form-item__label {
          line-height: 30px;
        }
        .el-select {
          width: 100%;
        }
      }

      .input {
        width: 100%;
        height: 30px;
      }
    }
    // 多行输入框
    .queryItem_text {
      min-width: 360px;
      width: 40%;
      margin: 3px 1%;
      border: 1px solid #cccccc;
      border-radius: 5px;
      display: flex;
      // align-items: center;
      //   justify-content: space-between;
      .queryLabel {
        width: 70px;
        min-width: 70px;
        color: #606266;
        text-align: right;
        margin-right: 10px;
      }
      ::v-deep {
        .el-form-item__content {
          width: calc(100% - 96px);
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
        .el-textarea__inner {
          border: none;
        }
      }
    }
  }

  .form_fq {
    margin-top: 20px;
  }

  .inpu {
    width: 180px;
  }
  .inpu1 {
    width: 210px;
  }
}
.formBox2 {
  width: 100%;

  ::v-deep {
    .el-table .el-table__cell {
      padding: 8px;
    }
  }
}

.formbox ::v-deep {
  .el-button {
    padding: 7px 10px !important;
  }
}

.box {
  width: 100%;
  padding: 10px 30px;
  box-sizing: border-box;
  text-align: center;
}

.box1 {
  background-color: #fff;
}

.addBtn_ {
  border: 1px solid #606266;
  padding: 5px !important;
  background-color: #fafafa;
  color: #333333;
}

.addBtn_ :hover {
  color: #606266 !important;
  border: 1px solid #ef7c1b;
}
.multipleclass {
  height: auto !important;
}
</style>
